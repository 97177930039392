import { connect } from 'react-redux'

import { getSnackbarQueue } from 'reducers'
import { dismissiSnackbarNotification as onDismiss } from 'actions/page/snackbar'

import { SnackbarQueue } from './SnackbarQueue'

export const ConnectedSnackbar = connect(
  (state, props) => ({
    queue: getSnackbarQueue(state, props)
  }),
  { onDismiss }
)(SnackbarQueue)

export default SnackbarQueue
