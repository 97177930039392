import PropTypes from 'prop-types'
import React from 'react'

import { ImageCloudinary } from 'components/ImageCloudinary'

import { useRouterLink } from 'hooks/router'
import { getRouteById } from 'config/router'

import { block } from 'utils/classnames'

/**
 * B2BLogo component
 * Renders a block element with a company-specific background image
 */
const B2BLogo = (props = {}) => {
  const {
    alt = '',
    publicId = '',
    href = ''
  } = props

  const handleClick = useRouterLink(getRouteById('b2b-panel'))

  if (!publicId) {
    return null
  }
  return (
    <div onClick={href ? undefined : handleClick} className={block('b2b-logo')}>
      <i className={block('icon-close')} />

      {
        href
          ? <a className={block('b2b-logo', 'link')} href={href} />
          : null
      }
      <ImageCloudinary
        publicId={publicId}
        alt={'logo ' + alt}
        className={block('b2b-logo', 'image')}
      />
    </div>
  )
}

/**
 * The B2BLogo component property types
 * @property { string='' } alt - A string to be used as the rendered component title attribute value
 * @property { string='' } src - The whole or part of the image URL
 * @property { string=''} className - The rendered element class attribute value
 * @property { string='' } href - An optional URL string to make the element a link
 */
B2BLogo.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string
}

export default B2BLogo
