import {
  Cloudinary,
  Util
} from 'cloudinary-core'

export const CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
export const UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET

export const CLOUDINARY_CONFIG = {
  cloudName: CLOUD_NAME,
  uploadPreset: UPLOAD_PRESET
}

export const CLOUDINARY_FOLDER_NAME = `newfoodcheri/${(
  process.env &&
  process.env.REACT_APP_ENV &&
  process.env.REACT_APP_ENV.toLowerCase()
  ) || 'xxx'}`

export const CLOUDINARY_UPLOAD_OPTIONS = {
  ...CLOUDINARY_CONFIG,
  multiple: false,
  folder: CLOUDINARY_FOLDER_NAME,
  tags: ['nfc'],
  resourceType: 'image',
  maxFileSize: null,
  maxImageWidth: null,
  maxImageHeight: null
}

export const url = (publicId, options) => {
  const scOptions = Util.withSnakeCaseKeys(options)
  const cl = Cloudinary.new({ secure: true, cloud_name: CLOUD_NAME })
  return cl.url(publicId, scOptions)
}

export const openUploadWidget = (options = {}, callback) => {
  options.tags = Array.isArray(options.tags) ? [...CLOUDINARY_UPLOAD_OPTIONS.tags, ...options.tags] : [...CLOUDINARY_UPLOAD_OPTIONS.tags]

  options = {
    ...CLOUDINARY_UPLOAD_OPTIONS,
    ...options
  }

  const scOptions = Util.withSnakeCaseKeys(options)
  window.cloudinary && window.cloudinary.openUploadWidget(scOptions, callback)
}

export const getCompanyLogoUrl = (fileName = '') => {
  if (!fileName) {
    return ''
  }

  if (fileName.includes('http')) {
    return fileName
  }

  return url(fileName)
}
