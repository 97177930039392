import PropTypes from 'prop-types'
import React from 'react'

import { getRouteById } from 'config/router'
import { useRouterLink } from 'hooks/router'

export const RouteButton = props => {
  const {
    route,
    routeId,
    params,
    buttonProps = {},
    Button,
    children,
    productId = null
  } = props

  const target = React.useMemo(() => route || getRouteById(routeId), [route, routeId])
  const handleClick = useRouterLink(target, params, false, false, productId)
  return Button
    ? <Button onClick={handleClick} {...buttonProps} />
    : (
      <button onClick={handleClick} {...buttonProps}>
        {children}
      </button>
    )
}
RouteButton.propTypes = {
  route: PropTypes.object,
  routeId: PropTypes.string,
  params: PropTypes.object,
  buttonProps: PropTypes.object,
  Button: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string
}
export default RouteButton
