import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useCallback } from 'react'
import { animated } from 'react-spring'

import Multiline from 'components/Common/Multiline'

import { useUnmountTransition } from 'hooks/animation'
import { block } from 'utils/classnames'

import './Snackbar.sass'

const b = block.with('snackbar')

const fadeTransition = {
  from: { opacity: 0, transform: 'translateY(-5px)' },
  enter: { opacity: 1, transform: 'translateY(0)' },
  leave: { opacity: 0, transform: 'translateY(-5px)' },
  config: { duration: 200 }
}

const MAXIMUM_STACK_LENGTH = 3

export const Snackbar = props => {
  const {
    id,
    message,
    position = 'top',
    type = 'success',
    timeout = 3000, // 3 seconds
    index = 0
  } = props

  const onDismiss = useCallback(() => { props.onDismiss && props.onDismiss(id) }, [id])

  const [
    transitions,
    handleDismiss
  ] = useUnmountTransition(onDismiss, fadeTransition, timeout)

  const bodyStyle = useMemo(
    () => index === 0
      ? undefined
      : { transform: `translateY(${index * 110}%)` },
    [index, position]
  )

  useEffect(() => {
    Math.abs(index) >= MAXIMUM_STACK_LENGTH && handleDismiss()
  }, [index])

  return transitions
    .map(({ item, key, props }) => (
      item
        ? (
          <animated.div
            key={key}
            className={b('', { position, index })}
            style={props}
          >
            <div
              style={bodyStyle}
              className={b('body', { type })}
            >
              <Multiline className={b('message')}>
                {message}
              </Multiline>
              <button
                className={b('dismiss')}
                onClick={handleDismiss}
              >
                Fermer
              </button>
            </div>
          </animated.div>
        )
        : null
    ))
}
Snackbar.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom']),
  type: PropTypes.oneOf(['success', 'error']),
  onDismiss: PropTypes.func,
  timeout: PropTypes.number,
  index: PropTypes.number
}

export default Snackbar
