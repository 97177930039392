import PropTypes from 'prop-types'
import React from 'react'

export const Multiline = props => {
  const {
    children = '',
    ...rest
  } = props

  const text = React.useMemo(() => (
    String(children)
      .split('\n')
      .map((line, i) => (
        line.trim()
          ? <p key={line + i} dangerouslySetInnerHTML={{ __html: line.trim() }} />
          : <br key={i} />
      ))
  ), [children])

  return (
    <div {...rest}>
      {text}
    </div>
  )
}
Multiline.propTypes = {
  children: PropTypes.string
}
export default Multiline
