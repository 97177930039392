import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'

import { block } from 'utils/classnames'
import href from 'config/hrefs'
import { getRouteById } from 'config/router'

import B2BLogo from './B2BLogo'
import RouteLink from 'components/Router/RouteLink'
import RouteButton from 'components/Router/RouteButton'

import './Header.sass'
import Router from 'next/router'
const b = block.with('header')

export const Header = props => {
  const {
    user = {},
    isHomepage = false,
    companies = null,
    company,
    isDemo = false,
    isDesktop,
    productId = null
  } = props

  const {
    firstName,
    isAdmin = false,
    referralProgram,
    b2b
  } = user

  const [isLoading, setIsLoading] = useState(false)
  const referredAmount = referralProgram?.referredAmount || 0
  const referrerAmount = referralProgram?.referrerAmount || 0
  const companyName = b2b?.companyName || null
  const isConnected = !!firstName
  const isB2b = isConnected && !!companyName
  const hasB2BOffers = !!company?.name
  const referral = useMemo(() => {
    const a = parseFloat(referredAmount)
    const b = parseFloat(referrerAmount)
    if (isNaN(a) || isNaN(b)) {
      return 0
    }
    return a + b
  }, [referredAmount, referrerAmount])

  const _backToHome = async () => {
    setIsLoading(true)
    await Router.push('/')
    setIsLoading(false)
  }

  return (
    <header className={block('header')}>
      {isDemo
        ? null
        : (
          <nav className={block('nav', { leftMenu: true })}>
            <ul className={block('nav', 'list')}>
              <li className={block('nav', 'item', { commitment: true })}>
                <a
                  className={block('nav', 'link')}
                  href={href.valuePage}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span className={block('text')}>Nos engagements</span>
                </a>
              </li>
              {
                isB2b
                  ? (
                    <li className={block('nav', 'item', { dashboard: true })}>
                      <a
                        className={block('nav', 'link')}
                        href={href.b2bDashboard}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <span className={block('text')}>Dashboard</span>
                      </a>
                    </li>
                  )
                  : (
                    <li className={block('nav', 'item', { company: true })}>
                      <a
                        className={block('nav', 'link')}
                        href={href.b2bLandingPage}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <span className={block('text')}>Offre entreprise</span>
                      </a>
                    </li>
                  )
              }
            </ul>
          </nav>
        )}

      <h1 className={block('logo-foodcheri')}>
        <RouteLink routeId='home'>
          <i className='icon-foodcheri' />
        </RouteLink>
        {
          isHomepage && hasB2BOffers && company && company.logo ? (
            <B2BLogo
              alt={companyName}
              publicId={company.logo}
            />
          ) : null
        }
      </h1>

      {isDemo
        ? null
        : (
          <nav className={block('nav', { rightMenu: true })}>
            <ul className={block('nav', 'list')}>
              {
                isHomepage && isConnected && (hasB2BOffers || companies) ? (
                  <li className={block('nav', 'item', { b2boffers: true })}>
                    <RouteButton
                      route={getRouteById('b2b-panel')}
                      params={{ step: '' }}
                    >
                      <div className={block('nav', 'link')}>
                        <i className={block('icon-building')} />
                        <span className={block('text')}>{company?.name || 'Mon offre'}</span>
                      </div>
                    </RouteButton>
                  </li>
                ) : null
              }
              {
                isConnected && firstName ? (
                  <li className={block('nav', 'item', { user: true })}>
                    {/* Should redirect to /account/orders as defined in routes.json if device is destop and to /account/menu if mobile */}
                    <RouteLink routeId={isDesktop ? 'orders' : 'menu'} className={block('nav', 'link')}>
                      <i className={block('icon-user')} />
                      <span className={block('text')}>{firstName}</span>
                    </RouteLink>
                  </li>
                ) : null
              }
              {
                isConnected && referral !== 0 ? (
                  <li className={block('nav', 'item', { referral: true })}>
                    <RouteLink routeId='referral' className={block('nav', 'link')}>
                      <i className={block('icon-gift-svg')} />
                      <span className={block('text')}>{referral}€</span>
                    </RouteLink>
                  </li>
                ) : null
              }
              {
                isConnected && isAdmin ? (
                  <li className={block('nav', 'item', { admin: true })}>
                    <a className={block('nav', 'link')} href={href.adminDashboard}>
                      <i className={block('icon-config')} />
                      <span className={block('text')}>Admin</span>
                    </a>
                  </li>
                ) : null
              }
              {
                (isHomepage || productId) && !isConnected
                  ? (
                    <li className={block('nav', 'item', { login: true })}>
                      <RouteButton productId={productId} route={getRouteById('auth')} params={{ step: '' }}>
                        <div className={block('nav', 'link')}>
                          <span className={block('text')}>Connexion</span>
                        </div>
                      </RouteButton>
                    </li>)
                  : null
              }
              {
                !isHomepage
                  ? (
                    <li className={block('nav', 'item')}>
                      <button onClick={_backToHome} className={block('nav', 'link', { homepage: true })}>
                        {!isLoading
                          ? 'Voir la carte'
                          : <div className={b('loading-spinner')} />}
                      </button>
                    </li>
                  )
                  : null
              }
            </ul>
          </nav>
        )}
    </header>
  )
}
Header.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    isAdmin: PropTypes.bool,
    referralProgram: PropTypes.shape({
      referredAmount: PropTypes.number,
      referrerAmount: PropTypes.number
    }),
    b2b: PropTypes.shape({
      companyName: PropTypes.string
    }),
    hasB2BOffers: PropTypes.bool,
    companies: PropTypes.object,
    company: PropTypes.object
  }),
  isHomepage: PropTypes.bool,
  isDemo: PropTypes.bool,
  isDesktop: PropTypes.bool
}

export default Header
