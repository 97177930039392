import PropTypes from 'prop-types'
import React from 'react'

import { Snackbar } from './Snackbar'

export const SnackbarQueue = props => {
  const {
    queue,
    onDismiss
  } = props

  if (!Array.isArray(queue)) {
    return null
  }

  return (
    <>
      {
        queue
          .filter(n => n.position !== 'bottom')
          .map((notification, i) => (
            <Snackbar
              {...notification}
              key={notification.id}
              onDismiss={onDismiss}
              index={i}
            />
          ))
      }
      {
        queue
          .filter(n => n.position === 'bottom')
          .map((notification, i) => (
            <Snackbar
              {...notification}
              key={notification.id}
              onDismiss={onDismiss}
              index={-i}
            />
          ))
      }
    </>
  )
}
SnackbarQueue.propTypes = {
  queue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      position: PropTypes.oneOf(['top', 'bottom'])
    })
  ),
  onDismiss: PropTypes.func
}
export default SnackbarQueue
