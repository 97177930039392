import {
  useEffect,
  useCallback,
  useRef
} from 'react'

export const useTimeout = (
  callback = null,
  timeout = null
) => {
  const timeoutIdRef = useRef()

  const cancel = useCallback(
    () => {
      const timeoutId = timeoutIdRef.current
      if (timeoutId) {
        timeoutIdRef.current = undefined
        clearTimeout(timeoutId)
      }
    },
    [timeoutIdRef]
  )

  useEffect(
    () => {
      if (typeof callback === 'function' && typeof timeout === 'number') {
        timeoutIdRef.current = setTimeout(callback, timeout)
      }

      return cancel
    },
    [callback, timeout, cancel]
  )

  return cancel
}

export const useInterval = (
  callback = null,
  timeout = null,
  shouldStartImmediately = false
) => {
  const intervalId = useRef()

  const cancel = useCallback(
    () => {
      const timeoutId = intervalId.current
      if (timeoutId) {
        intervalId.current = undefined
        clearInterval(timeoutId)
      }
    },
    [intervalId]
  )

  useEffect(
    () => {
      if (typeof callback === 'function' && typeof timeout === 'number') {
        intervalId.current = setInterval(callback, timeout)
        shouldStartImmediately && callback()
      }

      return cancel
    },
    [callback, timeout, cancel]
  )

  return cancel
}
