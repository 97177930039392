import React from 'react'
import PropTypes from 'prop-types'

import {
  CloudinaryContext,
  Image as CloudinaryImage,
  Transformation
} from 'cloudinary-react'

import {
  CLOUDINARY_CONFIG
} from 'utils/cloudinary'

export const ImageCloudinary = props => {
  const {
    className,
    publicId,
    width,
    height,
    crop = 'scale',
    style,
    alt
  } = props

  if (!publicId) {
    return null
  }

  return (
    <CloudinaryContext {...CLOUDINARY_CONFIG}>
      <CloudinaryImage
        className={className}
        publicId={publicId}
        height={height}
        crop={crop}
        style={{ width: width, boxSizing: 'border-box', ...style }}
        alt={alt}
      >
        <Transformation fetchFormat='auto' quality='auto' />
      </CloudinaryImage>
    </CloudinaryContext>
  )
}

ImageCloudinary.propTypes = {
  className: PropTypes.string,
  publicId: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  crop: PropTypes.string,
  style: PropTypes.object
}

export default ImageCloudinary
