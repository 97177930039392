import { connect } from 'react-redux'
import { Header } from './Header'

import { getMe, getUserCompany, isInsideWebapp, isDemoModeEnabled } from 'reducers'

import SmallHeaderCmp from './SmallHeader'

export const SmallHeader = SmallHeaderCmp

export const ConnectedSmallHeader = connect(
  (state, props) => ({
    user: getMe(state, props),
    company: getUserCompany(state, props),
    isInsideWebapp: isInsideWebapp(state, props),
    isDemo: isDemoModeEnabled(state, props),
    productId: props.productId
  })
)(SmallHeaderCmp)

export const ConnectedHeader = connect(
  (state, props) => ({
    user: getMe(state, props),
    company: getUserCompany(state, props),
    isDemo: isDemoModeEnabled(state, props),
    productId: props.productId
  })
)(Header)

export default ConnectedHeader
