import { useState, useMemo, useCallback } from 'react'
import { useTransition } from 'react-spring'
import { useTimeout } from 'hooks/timeout'

/**
 * useMountTransition
 * @description custom React hook that extends react-spring useTransition to animate a component upon unmount
 * @param  {function} onDismiss         callback function to remove the component
 * @param  {object}   transitionConfig  react-spring transition config
 * @param  {number}   timeout           optional timeout to automatically remove the component
 * @return {array}                      returns the react-spring transition array and a modified component dismiss handler
 */
export const useUnmountTransition = (onDismiss, transitionConfig, timeout = null) => {
  const [shouldShow, setShouldShow] = useState(true)
  const handleDismiss = useCallback(() => setShouldShow(false), [])
  const transition = useMemo(
    () => ({
      ...transitionConfig,
      onRest: (item, state) => { state === 'leave' && onDismiss && onDismiss() }
    }),
    [transitionConfig]
  )
  const transitions = useTransition(shouldShow, null, transition)

  useTimeout(handleDismiss, timeout)

  return [transitions, handleDismiss]
}
