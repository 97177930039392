import PropTypes from 'prop-types'
import React from 'react'
import { block } from 'utils/classnames'

import RouteLink from 'components/Router/RouteLink'

import './SmallHeader.sass'

const b = block.with('small-header')

export const SmallHeader = props => {
  const {
    CartButton = null,
    isDemo = false,
    user
  } = props

  const isConnected = !!user?.firstName

  return (
    <header className={b()}>
      {isDemo
        ? null
        : (
          <RouteLink routeId={isConnected ? 'menu' : 'auth'}>
            <div className={b('menu-button')} />
          </RouteLink>
        )}
      <h1 className={b('logo')}>
        <RouteLink routeId='home'>
          <i className='icon-foodcheri' />
        </RouteLink>
      </h1>
      {isDemo
        ? null
        : (
          <div className={b('cart-button')}>
            {CartButton}
          </div>
        )}
    </header>
  )
}
SmallHeader.propTypes = {
  user: PropTypes.object,
  company: PropTypes.object,
  CartButton: PropTypes.node,
  isInsideWebapp: PropTypes.bool,
  isDemo: PropTypes.bool,
  isProductPage: PropTypes.bool
}
export default SmallHeader
